<template>
  <ValidationObserver v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitResetPasswordForm)">
      <h1 class="text-h2 font-weight-bold mb-12">Set New <br> Password</h1>
      <div class="error pa-2 mb-4 text--white" v-if="resetError">{{ resetError }}</div>

      <ValidationProvider mode="eager" tag="label" name="New Password"
                          rules="min:8|max:64|required" vid="confirmation" v-slot="{ errors }">
        <div class="font-weight-bold text-body-1 mb-2">New Password</div>
        <v-text-field
          type="password"
          outlined
          hide-details="auto"
          v-model="password"
          placeholder="New Password"
        ></v-text-field>
        <div class="font-weight-bold text-body-1 text--error">{{ errors[0] }}</div>
      </ValidationProvider>

      <ValidationProvider mode="eager" tag="label" name="Password Confirm" rules="confirmed:confirmation|required"
                          v-slot="{ errors }">
        <div class="font-weight-bold text-body-1 mt-6 mb-2">Password Confirm</div>
        <v-text-field
          type="password"
          outlined
          hide-details="auto"
          v-model="confirmPassword"
          placeholder="Password Confirm"
        ></v-text-field>
        <div class="font-weight-bold text-body-1 text--error">{{ errors[0] }}</div>
      </ValidationProvider>

      <div class="d-flex justify-end align-center mt-8">
        <v-btn
          type="submit"
          rounded
          dark
          depressed
          large
          color="#ff5d28"
          class="text-h6 font-weight-bold text-capitalize px-13 py-7"
        >
          Reset password
        </v-btn>
      </div>
    </v-form>
  </ValidationObserver>
</template>

<script>
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import {extend} from 'vee-validate';
import {min, max, confirmed} from 'vee-validate/dist/rules';
import {recoverPasswordRequest} from "@/api/authorizations.api";

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} symbols.',
});

extend('max', {
  ...max,
  message: '{_field_} Password must have less than {length} symbols.',
});

extend('confirmed', {
  ...confirmed,
  message: '{_field_} doesn\'t match with the new password.',
});

export default {
  name: 'ResetPasswordForm',
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  data: () => ({
    password: '',
    confirmPassword: '',
    resetError: '',
    validationsTimeout: null,
  }),
  methods: {
    async submitResetPasswordForm() {
      const token = this.$route.query.password_reset_token;
      const response = await recoverPasswordRequest({
        token,
        password: this.password,
        password_confirmation: this.confirmPassword,
      });

      if (response.status === 404) {
        this.resetError = response.data.message;
      } else {
        this.$emit('showResetStatus', {
          success: true,
          message: 'Reset password was successfully made. Now you can log in with new password',
        });
        this.$router.push({name: 'login'});
        this.$emit('showLoginForm');
      }
    },
  }
}
</script>
